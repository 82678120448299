/* global angular */

'use strict';

function toastService($mdToast) {
    return {
        show: (message = '', type = null, showBtn = false, delay = 5000, position = 'bottom right') => {
            if (message === '') {
                return;
            }

            if (typeof message === 'object') {
                if (message.data && message.data.message) {
                    message = message.data.message;
                }
                if (message.message) {
                    message = message.message;
                }
            }

            if (showBtn === true && type === 'error') {
                delay = 1000 * 60 * 3; // 3 minutes
            }
            const theme = type ? 'md-' + type + '-toast-theme' : '';
            const icon = '<md-icon style="color: #fff; margin-right:0">' +
                    (type === 'error' ? 'cancel' : 'check') + '</md-icon>';
            const btn = showBtn ? ('<md-button class="md-icon-button md-accent" ' +
                    'style="padding-right:0;margin-right:0;" ng-click="close()">' +
                    icon + '</md-button>') : '';
            const template = '<md-toast class="' + theme + '" style="width: 100%;">' +
                    '<span class="md-toast-text cfff padding p10 f18 bold" flex>' + message + '</span>' +
                    btn + '</md-toast>';
            return $mdToast.show({
                hideDelay: delay,
                position: position,
                controller: 'ToastController',
                template: template
            });
        }
    };
}

angular.module('managerApp').factory('toast', toastService).controller('ToastController', ($scope, $mdToast) => {
    $scope.close = () => {
        $mdToast.hide();
    };
});
